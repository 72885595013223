import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { IconButton, Box } from '@mui/material';
import { COLORS, blurDataURL } from '@app/utilities/constants';
import { ProductService } from '@app/services/product.service';
import { useQuery } from 'react-query';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ProductCard from '@app/components/sections/product-card';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

export interface ICustomSwiper {
  handle: string;
  enableAuthor?: boolean;
  width?: {};
  initialData?: ProductService.List2;
  height?: {};
}

const CustomSwiper = ({ handle, enableAuthor, width, initialData, height }: ICustomSwiper) => {
  const {
    data: productData,
    isError,
    isLoading,
    isSuccess,
  } = useQuery(
    `GET_PRODUCT_BY_COLLECTION-${handle}`,
    () => {
      return ProductService.getCollection(handle);
    },
    { initialData }
  );

  const data = productData?.products;

  const swiperRef = React.useRef<any>(null);
  const [lftBtnDis, setLftBtnDis] = React.useState<boolean>(false);
  const [rgtBtnDis, setRgtBtnDis] = React.useState<boolean>(true);
  const [disableArrow, setDisableArrow] = React.useState<boolean>(false);

  if (isError || (!data?.length && isSuccess))
    return (
      <div
        style={{
          minHeight: '400px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          alignSelf: 'center',
        }}
      >
        Failed to load products, please refresh the page.
      </div>
    );

  return (
    <Box
      sx={{
        minHeight: '400px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
        marginRight: { xs: '6px', sm: '10px' },
        marginLeft: { xs: '6px', sm: '10px' },
      }}
    >
      {!disableArrow && (
        <IconButton
          sx={{
            display: { xs: 'none', sm: 'flex' },
            border: '1px solid lightgrey',
            zIndex: 200,
            marginRight: '-30px',
            background: 'white',
            height: 40,
            width: 40,
            borderRadius: 20,
            '&:hover': {
              background: 'white',
              border: '1px solid lightgrey',
              ...(lftBtnDis && {
                background: 'rgba(0,0,0,0.1)',
              }),
            },
            ...(lftBtnDis && {
              background: 'rgba(0,0,0,0.1)',
            }),
          }}
          onClick={() => {
            swiperRef.current.swiper.slidePrev();
          }}
          aria-label="delete"
        >
          <ChevronLeftIcon />
        </IconButton>
      )}

      <Swiper
        onAfterInit={(swiper) => {
          setLftBtnDis(swiper.isBeginning);
          setRgtBtnDis(swiper.isEnd);

          if (swiper?.isEnd && swiper?.isBeginning) {
            setDisableArrow(true);
          } else {
            setDisableArrow(false);
          }
        }}
        onReachBeginning={(swiper) => {
          setLftBtnDis(true);
        }}
        onReachEnd={(swiper) => {
          setRgtBtnDis(true);
        }}
        ref={swiperRef}
        // spaceBetween={5}
        watchSlidesProgress={true}
        threshold={2}
        slidesPerView={'auto'}
        className="mySwiper"
        touchStartPreventDefault={false}
        onSlideChange={(swiper) => {
          setLftBtnDis(swiper.isBeginning);
          setRgtBtnDis(swiper.isEnd);
        }}
      >
        {data?.map((i) => (
          <SwiperSlide style={{ width: 'auto' }} key={i?.id}>
            <ProductCard
              src={i?.image?.src}
              title={i?.title}
              amount={i?.price?.amt}
              compareAmt={i?.price?.compareAmt}
              url={i?.url}
              id={i?.varient?.id}
              isHome={true}
              curated_by={i?.curated_by}
              enableAuthor={Boolean(enableAuthor)}
              cstmStyle={{
                width: width ? width : { xs: 230, md: 300 },
                m: { xs: '15px 5px 15px 5px', sm: '15px' },
                boxShadow: '0px 4px 8px rgba(34, 44, 48, 0.05)',
              }}
              availableForSale={i?.availableForSale}
              productType={i?.productType}
              vendor={i?.vendor}
              handle={i?.handle}
              avgRating={i?.avgRating}
              ratingCount={i?.ratingCount}
            />
          </SwiperSlide>
        ))}
      </Swiper>

      {!disableArrow && (
        <IconButton
          onClick={() => {
            swiperRef.current.swiper.slideNext();
          }}
          sx={{
            display: { xs: 'none', sm: 'flex' },
            border: '1px solid lightgrey',
            // position: 'absolute',
            zIndex: 200,
            // left: 53,
            marginLeft: '-30px',
            background: 'white',
            height: 40,
            width: 40,
            borderRadius: 20,
            '&:hover': {
              background: 'white',
              border: '1px solid lightgrey',
              ...(rgtBtnDis && {
                background: 'rgba(0,0,0,0.1)',
              }),
            },
            ...(rgtBtnDis && {
              background: 'rgba(0,0,0,0.1)',
            }),
          }}
          aria-label="delete"
        >
          <ChevronRightIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default CustomSwiper;
