import formatTitle from 'title';
import { ShopifyService } from './shopify.service';
import { product_suitable_for_images } from '@app/utilities/usp_to_links.utility';
import moment from 'moment';
import { sanitize } from '@app/utilities/common.utility';

export type Blog = {
  items: Item[];
};

type SingleBlogByHandle = {
  title: string;
  image: string;
  handle: string;
  author: { src: string; name: string; designation: string };
  tags: string[];
  date: string;
};

interface BlogByHandle {
  title: string;
  handle: string;
  blog: SingleBlogByHandle[];
}

export interface Item {
  id: string;
  handle: string;
}
export interface Single {
  title: string;
  blog: {
    title: string;
    contentHtml: string;
    content: string;
    image: string;
    onlineUrl: string;
    author: { src: string; name: string; designation: string };
    date: string;
  };
}

export async function getBlogs(): Promise<Blog | undefined> {
  const blogs = await ShopifyService.getBlogs({ number: 40 });
  if (blogs.blogs.edges) {
    const items: Item[] = blogs.blogs.edges.map(({ node }) => {
      const item: Item = {
        id: node.id,
        handle: node.handle,
      };
      return item;
    });
    return {
      items: items,
    };
  }
}

export async function getBlogByIdAndArticleByHndle(handleBlog: string, handle: string): Promise<Single> {
  // console.log(handleBlog, handle);
  const blog = await ShopifyService.getBlogByIDAndArticleByHndle({ handleBlog, handle });
  // console.log('blog data', blog?.blog?.articleByHandle?.tags);
  if (!blog) {
    return blog;
  }

  const blogById: Single = {
    title: blog?.blog?.title,
    blog: {
      title: blog?.blog?.articleByHandle?.title,
      contentHtml: blog?.blog?.articleByHandle?.contentHtml,
      content: blog?.blog?.articleByHandle?.content,
      image: blog?.blog?.articleByHandle?.image?.url,
      onlineUrl: blog?.blog?.articleByHandle?.onlineStoreUrl,
      author: blog?.blog?.articleByHandle?.authorV2?.name,
      date: blog?.blog?.articleByHandle?.publishedAt,
      tags: blog?.blog?.articleByHandle?.tags,
    },
  };

  return blogById;
}

export async function blogByHandle(handle: string): Promise<BlogByHandle | undefined> {
  console.log(handle);
  const blog = await ShopifyService.getBlogByHandle({ handle });

  if (!blog) {
    return blog;
  }

  if (blog?.blog?.articles) {
    const items = blog?.blog?.articles?.edges.map(({ node }) => {
      const item = {
        title: node?.title,
        image: node?.image?.url,
        handle: node?.handle,
        author: node?.authorV2?.name,
        tags: node?.tags,
        date: node?.publishedAt,
      };
      return item;
    });

    return {
      title: blog?.blog?.title,
      handle: blog?.blog?.handle,
      blog: items as SingleBlogByHandle[],
    };
  }
}

export namespace BlogService {
  export const getBlogData = async (handle: string) => {
    try {
      const data = await blogByHandle(handle);

      if (data) {
        let newDataThree = data?.blog?.slice(0, 3),
          arr = [];
        for (const item of newDataThree) {
          let obj = {
            src: sanitize(item?.image),
            title: sanitize(item?.title),
            handle: sanitize(item?.handle),
            tag: item?.tags[0].replace('_', ' '),
            date: sanitize(moment(item?.date).format('MMM DD, YYYY')),
            author: {
              name: item?.author,
              designation: 'Head of Content and Food Blogger',
              src: 'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/Content_Writer_-_Ayushi.png?v=1639809247',
            },
          };
          arr.push(obj);
        }

        return arr;
      }
      return null;
    } catch (error) {
      if (error?.message === 'Network request failed') {
        console.log('error420', error?.message);
      }
    }
  };
}
