import { ReactNode, memo } from 'react';
import dynamic from 'next/dynamic';
const Header = dynamic(() => import('./header'));
import { UseBaseQueryResult } from 'react-query';

interface Props {
  query?: UseBaseQueryResult;
  children: ReactNode;
  theme?: 'dark' | 'light';
  isBoxProduct?: boolean;
}

const DefaultLayout = ({ query, children, theme, isBoxProduct }: Props) => {
  return (
    <div style={{ height: '100%', position: 'relative', ...(theme === 'dark' && { background: 'black' }) }}>
      <Header query={query} theme={theme} isBoxProduct={isBoxProduct} />
      {children}
    </div>
  );
};

export default memo(DefaultLayout);
