import { Swiper, SwiperSlide } from 'swiper/react';
import { Button, Typography, Box, Grid, SxProps, Theme } from '@mui/material';
import AdaptiveNextLink from '@app/components/common/AdaptiveNextLink';
import Image from '@app/components/common/unOptimizedNextjsImage';
import { COLORS } from '@app/utilities/constants';
import arrowRightIcon from '../../../../public/arrow-right.svg';
import { useState } from 'react';
import useAdaptiveRouter from '@app/hooks/useAdaptiveRouter';

interface IMostPopular {
  data: {
    title: string;
    desc: string;
    src: string;
    handle: string;
    products: string;
  };
}

function MostPopular({ data }: IMostPopular) {
  const [viewProdCount, setViewProdCount] = useState(false);
  const router = useAdaptiveRouter();

  return (
    <Box
      onClick={() => {
        router.push(`/collections/${data?.handle}`);
      }}
      onMouseOver={() => setViewProdCount(true)}
      onMouseLeave={() => setViewProdCount(false)}
      sx={{
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        ':hover .child': {
          color: COLORS.blue,
        },
        '.child': {
          color: 'black',
        },
      }}
    >
      <Box sx={{ position: 'relative', width: { xs: '76px', sm: '96px' }, height: { xs: '76px', sm: '96px' } }}>
        <Image src={data?.src} layout="fill" alt="Everything you need" />
      </Box>
      <Typography
        className="child"
        sx={{
          fontWeight: '600',
          fontSize: { xs: '12px', sm: '14px' },
          textAlign: 'center',
          display: '-webkit-box',
          overflow: 'hidden',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 2,
        }}
        variant="body2"
      >
        {data?.title}
      </Typography>
      <Typography
        sx={{
          display: { xs: 'none', sm: 'block' },
          fontWeight: '400',
          color: '#556166',
          fontSize: { xs: '10.5px', sm: '14px' },
          textAlign: 'center',
        }}
        variant="body2"
      >
        {data?.desc}
      </Typography>
      <Box sx={{ display: 'flex', mt: '5px', minHeight: '30px' }}>
        {viewProdCount && (
          <>
            <Typography
              className="child"
              sx={{ fontWeight: '400', fontSize: '12px', color: COLORS.blue, mr: '2px', mb: '1px' }}
              variant="body2"
            >
              {data?.products}
            </Typography>
            <Image src={arrowRightIcon} alt="arrowRightIcon" width={10} height={10} />
          </>
        )}
      </Box>
    </Box>
  );
}

export default MostPopular;
