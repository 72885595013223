import Image from '@app/components/common/unOptimizedNextjsImage';
import DefaultLayout from '@app/components/layouts/default-layout';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useQuery } from 'react-query';
import { ProductService } from '@app/services/product.service';
import { Button, Typography, Box, SxProps, Theme } from '@mui/material';
import { COLORS } from '@app/utilities/constants';
import { homePageConfig } from '@app/utilities/defaultConfig';
import { BlogService } from '@app/services/blog.service';
import { Footer } from '@app/components/sections/footer';
import HelpBuying from '@app/components/sections/Help-buying';
import { ICustomSwiper } from '@app/components/home/CustomSwiper';
import dynamic from 'next/dynamic';
import LazyHydrate from 'react-lazy-hydration';
import { postEventToMobile } from '@app/services/mobile.bridge';
import useAdaptiveRouter from '@app/hooks/useAdaptiveRouter';
import { NextSeo } from 'next-seo';

import { IMediaCard } from '@app/components/home/MediaCard';
import { IBlogCard } from '@app/components/home/BlogCard';
import { IFeaturedReviewSwiper } from '@app/components/home/FeaturedReviewSwiper';
import MostPopular from '@app/components/sections/categories/mostPopular';
import useWindowSize from '@app/hooks/useWindowSize';
import Carousel from '@app/components/home/Carousel';
import CustomSwiper from '@app/components/home/CustomSwiper';

const DynamicFeaturedReviewSwiper = dynamic(() => import('@app/components/home/FeaturedReviewSwiper'));
const DynamicBlogCard = dynamic(() => import('@app/components/home/BlogCard'));
const DynamicMediaCard = dynamic(() => import('@app/components/home/MediaCard'));
// const DynamicCarousel = dynamic(() => import('@app/components/home/Carousel'));
// const DynamicCustomSwiper = dynamic(() => import('@app/components/home/CustomSwiper'));

const FeaturedReviewSwiper = (props: IFeaturedReviewSwiper) => (
  <LazyHydrate whenVisible>
    <DynamicFeaturedReviewSwiper {...props} />
  </LazyHydrate>
);
const BlogCard = (props: IBlogCard) => (
  <LazyHydrate whenVisible>
    <DynamicBlogCard {...props} />
  </LazyHydrate>
);
const MediaCard = (props: IMediaCard) => (
  <LazyHydrate whenVisible>
    <DynamicMediaCard {...props} />
  </LazyHydrate>
);
// const Carousel = () => (
//   <LazyHydrate whenVisible>
//     <DynamicCarousel />
//   </LazyHydrate>
// );
// const CustomSwiper = (props: ICustomSwiper) => (
//   <LazyHydrate whenVisible>
//     <DynamicCustomSwiper {...props} />
//   </LazyHydrate>
// );

const infoConfig = [
  {
    title: 'Everything you need',
    desc: '4000+ healthy products across diets, goals and brands.',
    src: require('../../public/everything_u_need.svg'),
  },
  {
    title: 'Curated & verified',
    desc: 'Everything you see has been handpicked by experts.',
    src: require('../../public/curated.svg'),
  },
  {
    title: 'India’s healthiest brands',
    desc: 'From snacks to supplements, sweet to savory. It’s all here.',
    src: require('../../public/healthiest.svg'),
  },
  {
    title: 'Assisted, personalised buying',
    desc: 'Everyone is different. We help you pick what’s right for you.',
    src: require('../../public/assisted.svg'),
  },
  {
    title: 'Our kickass loyalty program',
    desc: 'Get rewards for every purchase. Health truly is wealth!',
    src: require('../../public/loyalty-pgm.svg'),
  },
  {
    title: 'Support small businesses!',
    desc: 'We’re a passionate startup. Your purchase helps us grow.',
    src: require('../../public/support-business.svg'),
  },
];

const mediaConfig = [
  {
    desc: '"Cora Healthcare is an ecommerce platform that sells health products on its platform. Cora was one of the 10 companies selected from among 1,677 entries as a part of  3rd cohort portfolio companies of Serial investor Sanjay Mehta-led venture capital firm 100X.VC” ',
    src: require('../../public/inc.svg'),
    color: '#FFF5F0',
  },
  {
    desc: '"Snigdha Kumar, a former Flipkart category head for foods, is now CEO of her own startup—Cora Health in Bengaluru—where she is building an AI-based recommendation engine to personalise healthy food and nutrition options for consumers"',
    src: require('../../public/Forbes.svg'),
    color: '#ECFCF7',
  },
  {
    desc: '"Most sweets in the market come with excess sugar and fat, making it unsuitable for people who are health-conscious or suffer from conditions like diabetes.Nowadays, they are more aware and will not settle for unhealthy options" . Cora curates healthy Indian food under categories such as snacks,sweets, ready-to-cook products, staples and superfoods."',
    src: require('../../public/Banglore-Mirror.svg'),
    color: '#FEFCED',
  },
  {
    desc: '"Nutritious food alternatives to tackle lifestyle diseases : Cora Health provides healthy food alternatives across various categories including breakfast, beverages, chocolates, sweets, and bakery items, among others. It sources original products directly from popular brands across India."',
    src: require('../../public/Yourstory.svg'),
    color: '#FBF5FC',
  },
];

const categoryConfig = [
  {
    title: 'PCOS',
    desc: "Women's Health",
    src: require('../../public/pcos.svg'),
    handle: 'pcos-pcod',
    products: 'View 25 Products',
  },
  {
    title: 'Body building ',
    desc: 'Getting lean or bulking up?',
    src: require('../../public/body-building.svg'),
    handle: 'body-building',
    products: 'View 144 Products',
  },
  {
    title: 'Ketogenic Diet ',
    desc: 'Desi Keto',
    src: require('../../public/kitogenic.svg'),
    handle: 'keto',
    products: 'View 65 Products',
  },
  {
    title: 'Immunity',
    desc: 'Holistic Health',
    src: require('../../public/immunity.svg'),
    handle: 'immunity-holistic-health',
    products: 'View 255 Products',
  },
  {
    title: 'Green Sustainable Foods',
    desc: 'Plant Based/Vegan',
    src: require('../../public/green-food.svg'),
    handle: 'vegan',
    products: 'View 93 Products',
  },
  {
    title: 'Clean Pantry',
    desc: 'No preservatives',
    src: require('../../public/clean-pantry.svg'),
    handle: 'clean-pantry',
    products: 'View 40 Products',
  },
  // {
  //   title: 'Guilt Free Snacking',
  //   desc: 'For healthier Options',
  //   src: require('../../public/snacking.svg'),
  //   handle: 'guilt-free-snacking',
  //   products: 'View 171 Products',
  // },
];

interface IHomePage {
  best_selling_collection: ProductService.List2;
  recommended_product_collection: ProductService.List2;
  blogsInitialData:
    | {
        src: string;
        title: string;
        handle: string;
        tag: string;
        date: string;
        author: {};
      }[]
    | null;
}

export default function Page(props: IHomePage) {
  const { width: windowWidth } = useWindowSize();
  const isMobile = windowWidth && windowWidth < 520;
  const showSmContent = Boolean(windowWidth && windowWidth < 900);
  const showXsContent = Boolean(windowWidth && windowWidth < 600);
  const hideForXsContent = Boolean(windowWidth && windowWidth < 600);

  const blogData = useQuery(
    `GET_BLOGS`,
    () => {
      return BlogService.getBlogData(homePageConfig?.featuredBlogs?.query);
    },
    { initialData: props?.blogsInitialData }
  );

  const router = useAdaptiveRouter();

  const InfoCards = ({ src, title, description }: { src: string; title: string; description: string }) => (
    <Box sx={{ flexDirection: 'row', maxWidth: { sm: 279, xs: 279 }, display: 'flex', marginTop: '12px' }}>
      <Image width="58px" height="58px" src={src} alt="Everything you need" />
      <Box sx={{ marginLeft: '16px' }}>
        <Typography variant="body2" sx={{ fontWeight: '700', fontSize: '16px' }}>
          {title}
        </Typography>
        <Typography variant="body2" sx={{ fontWeight: '400', color: COLORS.grey }}>
          {description}
        </Typography>
      </Box>
    </Box>
  );

  const styles = {
    title: {
      fontSize: { xs: '18px', sm: '26px', md: '30px' },
      color: COLORS.dark,
      fontFamily: 'Besley',
      fontWeight: '500',
      textAlign: 'center',
    } as SxProps<Theme>,
    sub_title: {
      color: COLORS.grey,
      fontSize: { xs: '12px', sm: '13px', md: '18px' },
      fontWeight: '400',
      textAlign: 'center',
      mt: '3px',
    } as SxProps<Theme>,
  };

  return (
    <DefaultLayout>
      <NextSeo
        title="Cora | India's largest Health Store. Wholesome Foods, Wholesale prices Cora Health"
        description="Trusted by 1Lakh+ Customers for Health Foods and Supplements. Get Premium, Preservative Free, Sugarfree Breakfast Essentials, Peanut Butter, Oats, Roasted and Guilt Free Snacks, Protein Supplements. Free Shipping & COD available. Get 50% Cashback on 5000+ products. Chat with Nutritionists for diet and product advise"
        openGraph={{
          type: 'website',
          url: window.location.href,
          title: "Cora | India's largest Health Store. Wholesome Foods, Wholesale prices Cora Health",
          description:
            'Trusted by 1Lakh+ Customers for Health Foods and Supplements. Get Premium, Preservative Free, Sugarfree Breakfast Essentials, Peanut Butter, Oats, Roasted and Guilt Free Snacks, Protein Supplements. Free Shipping & COD available. Get 50% Cashback on 5000+ products. Chat with Nutritionists for diet and product advise',
          images: [
            {
              url: 'https://corahealth.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flogo.ab00cc32.svg&w=2048&q=75',
              alt: 'Cora Health',
            },
          ],
          site_name: 'Cora Health',
        }}
      />
      <Carousel />

      <Box sx={{ margin: { xs: '10px', sm: '20px', md: '20px 40px 20px 40px' } }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '20px',
          }}
        >
          <Box sx={{ display: 'flex', flexGrow: { xs: 0, sm: 1 } }} />

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: { xs: 'flex-start', sm: 'center' },
              userSelect: 'none',
              mt: { xs: '20px', md: '35px' },
            }}
          >
            <Typography variant="h2" sx={styles.title}>
              {homePageConfig.best_selling.title}
            </Typography>
            <Typography variant="subtitle2" sx={styles.sub_title}>
              {homePageConfig.best_selling.sub_title}
            </Typography>
          </Box>
          <div style={{ display: 'flex', flexGrow: 1 }} />
          <Button
            onClick={() => {
              router.push(`/collections/${homePageConfig.best_selling.query}`);
            }}
            variant="outlined"
            sx={{
              color: COLORS.blue,
              fontSize: '12px',
              borderColor: COLORS.blue,
              textTransform: 'none',
              fontWeight: '600',
              padding: { xs: '0px', sm: '6px' },
              '&:hover': {
                color: COLORS.blue,
                borderColor: COLORS.blue,
              },
              borderRadius: '6px',
              mr: { xs: '0px', md: '40px' },
            }}
          >
            View All
          </Button>
        </div>

        <CustomSwiper handle={homePageConfig.best_selling.query} initialData={props?.best_selling_collection} />
      </Box>

      <Box
        sx={{
          background: COLORS.lightBlue,
          mt: { xs: '-120px', sm: '-100px' },
          pb: { xs: '65px', sm: '95px' },
          userSelect: 'none',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            paddingTop: { xs: '120px', sm: '140px' },
            mb: '20px',
          }}
        >
          <Typography variant="h3" sx={styles.title}>
            {'Hi! We’re thrilled you’re here.'}
          </Typography>
          <Typography variant="h3" sx={styles.title}>
            {'Why buy from us?'}
          </Typography>
        </Box>
        <Box
          sx={{
            justifyContent: 'center',
            flex: 1,
            display: { xs: 'block', md: 'flex' },
            mr: { xs: '10px', sm: '20px' },
            ml: { xs: '10px', sm: '20px' },
            mt: { xs: '20px', md: '44px' },
          }}
        >
          <Box
            sx={{
              maxWidth: '1070px',
              flex: 1,
              flexWrap: 'wrap',
              flexDirection: 'row',
              display: 'flex',
              justifyContent: 'space-around',
            }}
          >
            {showSmContent && (
              <Box sx={{ display: { md: 'none' }, marginTop: { xs: '0px', sm: '25px' }, width: '100%' }}>
                <Swiper
                  spaceBetween={8}
                  watchSlidesProgress={true}
                  slidesPerView={'auto'}
                  className="mySwiper"
                  style={{ paddingLeft: '10px', paddingRight: '10px' }}
                  threshold={2}
                >
                  {infoConfig.map((i) => (
                    <SwiperSlide key={i.title} style={{ width: 'auto' }}>
                      <Box sx={{ padding: '4px 10px 4px 10px', borderRadius: '8px', background: 'white' }}>
                        <InfoCards src={i?.src} title={i?.title} description={i?.desc} />
                      </Box>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Box>
            )}

            <Box
              sx={{
                display: { md: 'flex', xs: 'none' },
                flex: 1,
                flexWrap: 'wrap',
                flexDirection: 'row',
                justifyContent: 'space-around',
                marginRight: '20px',
                marginLeft: '20px',
                background: 'white',
                padding: '14px',
              }}
            >
              {infoConfig.map((i) => (
                <InfoCards key={i.title} src={i?.src} title={i?.title} description={i?.desc} />
              ))}
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            paddingTop: { xs: '55px', sm: '80px' },
            // background: 'red',
            pr: '10px',
            pl: '10px',
          }}
        >
          <Typography variant="h3" sx={styles.title}>
            {'Let’s help you shop.'}
          </Typography>
          <Typography variant="h6" sx={styles.sub_title}>
            {'Here are our most popular categories of products.'}
          </Typography>
        </Box>
      </Box>

      {!hideForXsContent && (
        <Box sx={{ mt: '-50px', display: { xs: 'none', sm: 'flex' }, paddingBottom: '30px' }}>
          <Swiper spaceBetween={8} watchSlidesProgress={true} slidesPerView={'auto'} className="mySwiper" style={{}}>
            {categoryConfig.map((i, index) => (
              <SwiperSlide
                key={index}
                style={{
                  width: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  marginRight: '15px',
                  alignItems: 'center',
                  marginLeft: '15px',
                  minWidth: '140px',
                }}
              >
                <MostPopular data={i} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      )}

      {showXsContent && (
        <Box
          sx={{
            mt: { xs: '-40px', sm: '-45px' },
            display: { xs: 'flex', sm: 'none' },
            justifyContent: 'center',
            flexWrap: 'wrap',
            background: COLORS.lightBlue,
          }}
        >
          {categoryConfig.map((i, index) => (
            <Box
              key={index}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: windowWidth ? windowWidth / 3.1 : '100px',
              }}
            >
              <MostPopular data={i} />
            </Box>
          ))}
        </Box>
      )}

      <Button
        onClick={() => {
          router.push('/collections');
        }}
        variant="contained"
        sx={{
          color: '#FFFFFF',
          background: COLORS.green,
          width: '166px',
          height: '48px',
          marginBottom: '8px',
          textTransform: 'none',
          '&:hover': {
            background: COLORS.green,
          },
          margin: '0 auto',
          display: 'flex',
          mt: '50px',
        }}
      >
        View All Category
      </Button>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          pt: { xs: '40px', sm: '80px' },
          pl: '10px',
          pr: '10px',
          pb: { xs: '0px', sm: '25px' },
          userSelect: 'none',
        }}
      >
        <Typography variant="h3" sx={styles.title}>
          {'Staff Picks'}
        </Typography>
        <Typography variant="h6" sx={styles.sub_title}>
          {"Some of the most innovative and useful products that we've tried personally"}
        </Typography>
      </Box>
      <Box sx={{ padding: { xs: '0px', sm: '20px' }, ml: '14px', mr: '10px' }}>
        <CustomSwiper
          handle={homePageConfig?.recommended_product?.query}
          enableAuthor
          width={{ xs: '220px', sm: '270px', md: '300px' }}
          initialData={props?.recommended_product_collection}
          height={{ height: { xs: '490px' } }}
        />
      </Box>
      <Box
        sx={{
          background: COLORS.lightYellow,
          marginTop: { xs: '-170px', sm: '-150px' },
          display: 'flex',
          justifyContent: 'center',
          pb: { xs: '60px', sm: '120px' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            paddingTop: '200px',
            maxWidth: '640px',
            flex: 1,
            pl: '10px',
            pr: '10px',
          }}
        >
          <Typography variant="h3" sx={styles.title}>
            {'Happy Folks!'}
          </Typography>
          <Typography variant="h6" sx={styles.sub_title}>
            {
              'Our customers range from athletes to pregnant women, and just regular folks trying to live healthier. See what some of them have to say about us!'
            }
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          marginBottom: { xs: '0px', sm: '40px' },
          mt: '-90px',
          ml: { xs: '10px', md: '145px' },
          mr: { xs: '10px', md: '145px' },
        }}
      >
        <FeaturedReviewSwiper data={homePageConfig.featuredReview} />
      </Box>
      {/* {!hideForXsContent && (
        <Box
          sx={{
            flexDirection: 'row',
            display: { xs: 'none', sm: 'flex' },
            alignItems: 'center',
            justifyContent: 'center',
            margin: '20px',
          }}
        >
          <Box>
            <Typography sx={{ fontSize: '30px', fontWeight: '700' }}>Get Personalised</Typography>
            <Typography sx={{ fontSize: '30px', fontWeight: '700' }}> Recommendations</Typography>
            <Typography sx={{ fontSize: '18px', fontWeight: '400' }}>
              Talk to our experts. To help you get the right products
            </Typography>
            <Typography> for your goal and lifestyle.</Typography>
            <Button
              disabled
              // component="a"
              // href="https://api.whatsapp.com/send?phone=916366100530&text=I+want+to+speak+to+someone+before+ordering"
              variant="contained"
              sx={{
                color: '#FFFFFF',
                background: COLORS.green,
                height: '48px',
                textTransform: 'none',
                marginTop: '50px',
                '&:hover': {
                  background: COLORS.green,
                },
              }}
            >
              {'Book Free Consultation'}
            </Button>
          </Box>
          <Image
            alt=""
            width={300}
            height={400}
            src={
              'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/Untitled_design-22_3feb556b-a816-4297-a0ce-74c4856d3f29.png?v=1640694896'
            }
            priority
          />
        </Box>
      )}
      {showXsContent && (
        <Box
          sx={{
            display: { xs: 'flex', sm: 'none' },
            flexDirection: 'row',
            background: COLORS.lightBlue,
            justifyContent: 'center',
            alignItems: 'center',
            padding: '14px',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', mr: '4%' }}>
            <Typography sx={{ fontWeight: '700', fontSize: '16px' }}>{'Need Help Buying?'}</Typography>
            <Typography sx={{ color: COLORS.grey, mt: '3px' }}>{'Talk to our experts'}</Typography>
            <Button
              variant="outlined"
              onClick={() => {
                postEventToMobile('chat_now', undefined);
              }}
              sx={{
                width: '116px',
                height: '40px',
                borderColor: COLORS.blue,
                color: COLORS.blue,
                textTransform: 'none',
                fontSize: '14px',
                padding: 0,
                mt: '12px',
                background: 'white',
                '&:hover': {
                  borderColor: COLORS.blue,
                },
              }}
            >
              {'Consult Now'}
            </Button>
          </Box>
          <Image
            alt=""
            width={120}
            height={160}
            src={
              'https://cdn.shopify.com/s/files/1/0520/5272/1845/files/Untitled_design-22_3feb556b-a816-4297-a0ce-74c4856d3f29.png?v=1640694896'
            }
            priority
          />
        </Box>
      )} */}
      <Box
        sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', paddingTop: { xs: '40px', sm: '80px' } }}
      >
        <Typography variant="h3" sx={styles.title}>
          {'Recognized in the media'}
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flex: 1,
          margin: { xs: '30px 10px 0px 20px', sm: '45px 20px 0px 20px', md: '45px 75px 0px 75px' },
        }}
      >
        <Swiper spaceBetween={8} watchSlidesProgress={true} slidesPerView={'auto'} className="mySwiper">
          {mediaConfig.map((i) => (
            <SwiperSlide key={i.desc} style={{ width: 'auto', background: 'white', padding: '0px' }}>
              <MediaCard src={i?.src} desc={i?.desc} color={i?.color} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          paddingTop: { xs: '0px', sm: '80px' },
          pl: '10px',
          pr: '10px',
        }}
      >
        <Typography variant="h3" sx={styles.title}>
          {'Blogs and Health Guides'}
        </Typography>
        <Typography variant="h6" sx={styles.sub_title}>
          {'Wondering if that diet is right for you? Finding a weekend recipe?'}
        </Typography>
        <Typography variant="h6" sx={{ ...styles.sub_title, mt: 0 }}>
          {'We’ve got you covered.'}
        </Typography>
      </Box>

      <Box sx={{ margin: '45px 20px 0px 20px', alignItems: 'center', display: 'flex' }}>
        <Swiper style={{}} spaceBetween={8} watchSlidesProgress={true} slidesPerView={'auto'} className="mySwiper">
          {blogData?.data?.map((i, index) => (
            <SwiperSlide
              key={index}
              style={{ width: 'auto', background: 'white', padding: '4px 10px 4px 10px', cursor: 'pointer' }}
              onClick={() =>
                router.push({ pathname: '/blogs/' + homePageConfig?.featuredBlogs?.query + '/' + i?.handle })
              }
            >
              <BlogCard {...i} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          paddingTop: '30px',
          pl: '10px',
          pr: '10px',
          marginBottom: '50px',
        }}
      >
        <Button
          onClick={() => {
            router.push('/blogs/' + homePageConfig?.featuredBlogs?.query);
          }}
          variant="contained"
          fullWidth={!!isMobile}
          sx={{
            color: '#FFFFFF',
            background: COLORS.green,
            height: '48px',
            textTransform: 'none',
            borderRadius: '8px',
            fontSize: '14px',
            fontFamily: 'Public Sans',
            '&:hover': {
              background: COLORS.green,
            },
          }}
        >
          {'View All posts'}
        </Button>
      </Box>

      <HelpBuying />
      <Footer />
    </DefaultLayout>
  );
}

export async function getStaticProps() {
  try {
    const best_selling_collection = await ProductService.getCollection(homePageConfig?.best_selling?.query);
    const recommended_product_collection = await ProductService.getCollection(
      homePageConfig?.recommended_product.query
    );
    const blogsInitialData = await BlogService.getBlogData(homePageConfig?.featuredBlogs?.query);

    return { props: { best_selling_collection, recommended_product_collection, blogsInitialData } };
  } catch (error) {
    console.log('error', error);

    return { props: { best_selling_collection: null, recommended_product_collection: null, blogsInitialData: null } };
  }
}
