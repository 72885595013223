import { Grid, Typography, Button, Card, Box } from '@mui/material';
import { COLORS } from '@app/utilities/constants';
import FounderImage from '../../../public/founder-image.svg';
import DrShweta from '../../../public/dr-shweta.png';
import Image from '@app/components/common/unOptimizedNextjsImage';
import truck from '../../../public/index-truck.svg';
import percent from '../../../public/index-percentage.svg';
import verify from '../../../public/index-verify.svg';
import orderPack from '../../../public/index-package.svg';
import Link from 'next/link';
import { postEventToMobile } from '@app/services/mobile.bridge';

interface IHelpBuying {
  imgType?: number;
  isBottomCardHidden?: boolean;
  theme?: string;
}
function Index({ imgType, isBottomCardHidden, theme }: IHelpBuying) {
  const isDarkTheme = theme === 'dark' ? true : false;

  var imageurl = FounderImage?.src;
  if (imgType === 1) {
    imageurl = DrShweta?.src;
  }

  const HelpBuyingTheme = {
    container: {
      flexDirection: 'row',
      display: { xs: 'none', sm: 'flex' },
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#F3F8FA',
      ...(isDarkTheme && { backgroundColor: '#131313' }),
    },
    title: {
      fontFamily: 'Public Sans',
      fontSize: '30px',
      fontWeight: '700',
      marginBottom: '10px',
      ...(isDarkTheme && { color: COLORS.white }),
    },
    subtitle: {
      fontSize: '18px',
      marginBottom: '20px',
      color: '#556166',
      fontFamily: 'Public Sans',
      fontWeight: '400',
      ...(isDarkTheme && { color: COLORS.lightGrey2 }),
    },
    bold: {
      ...(isDarkTheme && { color: COLORS.white }),
    },
    m_container: {
      display: { xs: 'flex', sm: 'none' },
      flexDirection: 'row',
      background: COLORS.lightBlue,
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '100px',
      ...(isDarkTheme && { backgroundColor: '#131313' }),
    },
    m_title: {
      fontWeight: '700',
      fontSize: '16px',
      fontFamily: 'Public Sans',
      ...(isDarkTheme && { color: COLORS.white }),
    },
  };

  return (
    <>
      <Box sx={HelpBuyingTheme.container}>
        <Box>
          <Typography sx={HelpBuyingTheme.title}>Need Help Buying?</Typography>
          <Typography sx={HelpBuyingTheme.subtitle}>
            Call our support team between <br />
            10 am to 6 pm at <b style={HelpBuyingTheme.bold}>+91 7353008799</b>
          </Typography>
          <Link
            href={'https://api.whatsapp.com/send?phone=916366100530&text=I+want+to+speak+to+someone+before+ordering'}
            passHref
          >
            <Button
              // disabled
              variant="outlined"
              sx={{
                width: '136px',
                height: '40px',
                fontSize: '14px',
                fontWeight: '600',
                textTransform: 'none',
                borderColor: COLORS.blue,
                backgroundColor: COLORS.white,
                borderRadius: '8px',
                color: COLORS.blue,
                '&:hover': {
                  borderColor: COLORS.blue,
                  color: COLORS.blue,
                },
              }}
            >
              Connect Now
            </Button>
          </Link>
        </Box>
        <Box sx={{ width: '300px', height: '340px', position: 'relative', marginBottom: '30px', marginTop: '0px' }}>
          <Image alt="" src={imageurl} layout="fill" objectFit="inherit" />
        </Box>
      </Box>

      <Box sx={HelpBuyingTheme.m_container}>
        <Box sx={{ display: 'flex', flexDirection: 'column', mr: '1%', paddingLeft: '10px' }}>
          <Typography sx={HelpBuyingTheme.m_title}>{'Need Help Buying?'}</Typography>
          <Typography sx={{ color: COLORS.grey, mt: '3px', marginBottom: '10px', fontFamily: 'Public Sans' }}>
            {'Chat with us'}
          </Typography>
          <Button
            onClick={() => {
              postEventToMobile('chat_now', undefined);
            }}
            variant="outlined"
            sx={{
              width: '120px',
              height: '40px',
              fontSize: '14px',
              fontWeight: '600',
              textTransform: 'none',
              borderColor: COLORS.blue,
              backgroundColor: COLORS.white,
              borderRadius: '8px',
              color: COLORS.blue,
              '&:hover': {
                borderColor: COLORS.blue,
                color: COLORS.blue,
              },
            }}
          >
            Connect Now
          </Button>
        </Box>
        <Image src={imageurl} alt={'Need Help Buying?'} width={155} height={202} />
      </Box>
      {!isBottomCardHidden && (
        <Box
          sx={{
            borderRadius: '8px',
            mb: '18px',
            mx: { xs: '14px', sm: '20px', md: '80px', mt: { xs: '-120px', sm: '-40px' } },
          }}
        >
          <Grid
            container
            justifyContent={'center'}
            alignItems="center"
            // spacing={3}
            sx={{
              background: 'white',
              mt: { xs: '-120px', sm: '-40px' },
              borderRadius: '8px',
            }}
          >
            {[
              { src: truck, title: 'Free Shipping on orders above Rs. 299/-' },
              { src: percent, title: 'Best Discounts Affordable Prices' },
              { src: verify, title: '100% Genunine Products Directly from brands' },
              { src: orderPack, title: 'Freshest Healthy Handpicked Products' },
            ].map((i) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                key={i?.title}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  p: '10px',
                }}
              >
                <Image src={i?.src} alt={i?.title} width={'60px'} height={'60px'} />
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: '400',
                    fontFamily: 'Public Sans',
                    maxWidth: { xs: 'none', sm: '200px' },
                    ml: { xs: '10px', sm: '12px', md: '24px' },
                  }}
                >
                  {i?.title}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </>
  );
}

export default Index;
