import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Box } from '@mui/material';
import Image from '@app/components/common/unOptimizedNextjsImage';
import { homePageConfig } from '@app/utilities/defaultConfig';
import { COLORS, blurDataURL, TRY_NEW_FEATURE } from '@app/utilities/constants';
import useWindowSize from '@app/hooks/useWindowSize';
import { useAppSelector } from '@app/state/hooks';
import useAdaptiveRouter from '@app/hooks/useAdaptiveRouter';
import SwiperCore, { Autoplay, Pagination } from 'swiper';

SwiperCore.use([Autoplay, Pagination]);

function Carousel() {
  const router = useAdaptiveRouter();
  const { width, height } = useWindowSize();
  const shopifyCustomer = useAppSelector((state) => state?.userDetails);
  const isMobile = width && width < 620;

  return (
    <Box
      sx={(theme) => ({
        height: { xs: '290px', sm: '300px', lg: '430px', xl: '500px' },
        [theme.breakpoints.down(470)]: {
          height: '250px',
        },
        [theme.breakpoints.down(400)]: {
          height: '200px',
        },
        [theme.breakpoints.up(1650)]: {
          height: '520px',
        },
        [theme.breakpoints.up(1900)]: {
          height: '600px',
        },
        [theme.breakpoints.up(2200)]: {
          height: '800px',
        },
        width: '100%',
      })}
    >
      <Swiper
        spaceBetween={15}
        centeredSlides={true}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        loop
        className="mySwiper"
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        {homePageConfig?.carouselConfig?.map((i, index) => {
          if (i?.handle == 'ask-for-email') {
            if (localStorage.getItem(TRY_NEW_FEATURE) != 'true') return null;
            if (shopifyCustomer?.customer?.customerId) return null;
            if (!shopifyCustomer?.uid) return null;
          }
          return (
            <SwiperSlide key={index} style={{ cursor: 'pointer', userSelect: 'none' }}>
              {i?.src && (
                <Box
                  sx={{ position: 'relative', width: '100%', height: '100%' }}
                  onClick={() => {
                    if (i.type === 'link') {
                      window.open(i?.handle);
                    } else if (i.type === 'blog') {
                      router.push(`/blogs/${i?.handle}`);
                    } else if (i.type === 'route') {
                      router.push(i?.handle);
                    } else {
                      router.push(`/collections/${i?.collection}`);
                    }
                  }}
                  // href={`/collections/${i?.collection}`}
                >
                  <Image
                    quality={60}
                    alt={i?.alt}
                    src={isMobile ? i?.mobile_src : i?.src}
                    layout="fill"
                    objectFit={isMobile ? 'contain' : 'unset'}
                    priority
                    placeholder="blur"
                    blurDataURL={blurDataURL}
                    sizes="(min-width: 75em) 90vw,
                     (min-width: 48em) 70vw,
                    90vw"
                  />
                </Box>
              )}
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Box>
  );
}

export default Carousel;
