declare global {
  interface Window {
    clevertap: any;
  }
}

export namespace ClevertapService {
  export interface IUserLogin {
    Name?: string;
    Identity?: string | number;
    Email?: string;
    Phone?: string;
    Gender?: 'M' | 'F';
    subscriptionType?: string;
    'Last Name'?: string;
    Country?: string;
    City?: string;
    'Pin Code'?: string;
    'First order date'?: string;
    'Last order date'?: string;
    'Order Count'?: number;
    'Total Spend'?: number;
    'Has Cora Account'?: boolean;
  }

  interface IRecordEvent {
    eventName: string;
    eventData: {};
  }

  export const userLogin = (identityData: IUserLogin) => {
    try {
      if (typeof window !== 'undefined') {
        window?.clevertap?.onUserLogin?.push?.({
          Site: {
            ...identityData,
          },
        });
      }
    } catch (error) {
      console.log('clevertap error');
    }
  };

  export const updateUserProfile = (identityData: IUserLogin) => {
    try {
      if (typeof window !== 'undefined') {
        window?.clevertap?.profile?.push?.({
          Site: {
            ...identityData,
          },
        });
      }
    } catch (error) {
      console.log('clevertap error');
    }
  };

  export const recordEvent = (event: IRecordEvent) => {
    try {
      const { eventData, eventName } = event;
      console.log('event' + eventName, eventData);

      if (typeof window !== 'undefined') {
        window?.clevertap?.event?.push?.(eventName, {
          ...eventData,
        });
      }
    } catch (error) {
      console.log('clevertap error');
    }
  };

  export const notificationPermission = () => {
    try {
      window?.clevertap?.notifications?.push?.({
        // apnsWebPushId: '<apple web push id>', //only for safari browser
        // apnsWebPushServiceUrl: '<safari package service url>', //only for safari browser
        titleText: 'Would you like to receive Push Notifications?',
        bodyText: 'We promise to only send you relevant content and give you updates on your transactions',
        okButtonText: 'Sign me up!',
        rejectButtonText: 'No thanks',
        okButtonColor: '#f28046',
        askAgainTimeInSeconds: 50000,
      });
      console.log('clevertap notif Permission');
    } catch (error) {
      console.log('clevertap notif Permission err', error);
    }
  };
}
